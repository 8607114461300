import { useEffect, useState } from "react";
import SendIcon from "../svgs/Send";


interface InputProps {
  addUserMessage: (str: string) => void;
  isMessageAllowed: boolean;
  placeholder?: string;
  startText?: string;
}

export default function InputContainer({
  addUserMessage,
  isMessageAllowed,
  placeholder = "Message Kuration AI...",
  startText = "",
}: InputProps) {
  const [input, setInput] = useState(startText);
  const [height, setHeight] = useState(1);
  const [line, setLine] = useState(1); //count how many "\n" in the textarea

  useEffect(() => {
    setInput('');
    setInput(startText);
  }, [startText]);

  function handleMessageSend() {
    if (!isMessageAllowed) {
      return;
    }
    if (input.trim().length > 0) {
      addUserMessage(input.trim());
    }
    setInput("")
  }

  function countNewlines(str: string) {
    return (str.match(/\n/g) || []).length;
  }

  return (
    <div className="relative">
      <textarea
        rows={height}
        placeholder={placeholder}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={(e) => {
          // if user pressed shift + enter -> go to new line.
          // if user only pressed enter -> hit send.   
          if (!e.shiftKey && e.key === "Enter") {
            e.preventDefault();
            handleMessageSend();
            setHeight(1);
            setLine(1);
          } else if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault();
            if (height < 10) {
              setHeight(height + 1);
            }
            setLine(line + 1);
            setInput(prevInput => prevInput + "\n");
          } else if (e.key === 'Backspace') {
            const inputElement = e.target as HTMLTextAreaElement;
            const cursorPosition = inputElement.selectionStart || 0;
            const contentBefore = inputElement.value;
            const contentAfter = contentBefore.slice(0, cursorPosition - 1) + contentBefore.slice(cursorPosition);
            // After deletion, update the line count
            const newLineCount = countNewlines(contentAfter)
            setLine(newLineCount);
            if (newLineCount + 1 < height) {
              setHeight(newLineCount + 1);
            }
          }
        }}
        className={`peer
        inline-flex shadow-[2px_2px_20px_0px_#00000014] w-full resize-none items-center justify-center gap-2.5 rounded-md border-2  border-neutral-400 bg-white  py-3.5 pl-3 pr-11  outline-none hover:border-purple   md:pl-4`}
      ></textarea>
      <button
        onClick={() => {
          handleMessageSend();
        }}
        className={`bottom-4 absolute right-2.5 flex h-8 w-8 items-center justify-center gap-2.5 rounded bg-neutral-300 p-[7px]  outline-none   focus:bg-purpleHover ${input.length > 0
          ? "bg-purple hover:bg-purpleHover"
          : " peer-focus:bg-lightpurple "
          }   `}
      >
        <SendIcon height="17.14px" width="17.14px" />
      </button>
    </div>
  );
}

export function InputWithSuggestion({
  addUserMessage,
  placeholder = "Ask anything—your imagination is the only limit!",
  isMessageAllowed = true,
}: InputProps) {
  const [startText, setStartText] = useState('');
  function resetInnerTextToNew(str: string) {
    // TODO: fix this with lifting the state up instead of timeout hack.
    setStartText('');
    setTimeout(() => {
      try {
        setStartText(str);
      } catch (e) {
        console.log(e);
      }
    }, 1);
  }

  return (
    <div className="flex flex-col">
      <InputContainer
        startText={startText}
        addUserMessage={addUserMessage}
        isMessageAllowed={isMessageAllowed}
        placeholder={placeholder}
      />
      <div className="inline-flex w-full flex-col items-start justify-start gap-0.5">
        <div className="inline-flex w-96 items-center justify-center gap-2.5 py-2.5 pr-2.5">
          <div className="ml-[5px] w-96 text-base font-normal leading-snug text-neutral-400">
            Suggestion search:{" "}
          </div>
        </div>
        <div className="inline-flex w-full items-start justify-start gap-3">
          <button className="flex items-center justify-center gap-2.5 rounded-md border border-transparent bg-indigo-100 px-3 py-2 outline-1 hover:border-purple hover:outline-purple focus:outline-purple">
            <div className="text-lg font-normal leading-relaxed text-indigo-400" onClick={() => { resetInnerTextToNew('map out a market') }}>
              map out a market
            </div>
          </button>
          <button className="flex items-center justify-center gap-2.5 rounded-md border border-transparent bg-indigo-100 px-3 py-2 outline-1 hover:border-purple hover:outline-purple focus:outline-purple">
            <div onClick={() => { resetInnerTextToNew('enrich a list') }} className="text-lg font-normal leading-relaxed text-indigo-400">
              enrich a list
            </div>
          </button>
          <button className="flex items-center justify-center gap-2.5 rounded-md border border-transparent bg-indigo-100 px-3 py-2 outline-1 hover:border-purple hover:outline-purple focus:outline-purple">
            <div className="text-lg font-normal leading-relaxed text-indigo-400" onClick={() => { resetInnerTextToNew('find new clients') }}>
              find new clients
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
