import { useEffect, useState } from "react";
import { TableRow } from "src/models/data";
import { useParams } from "react-router-dom";
import { getCount, getPaginatedData } from "src/firebase/utils";
import TableView from "./TableView";
import { downloadList } from "../utils";
import { Table } from "src/hooks/useChatTable";
import useTableRows from "src/hooks/useTableRows";
import useChatUpdates from "src/hooks/useChatUpdates";
import { AltColumn } from "./tableUtils";
import { toast } from "react-toastify";

export function DefaultTable({ table }: { table: Table }) {
  const tableId = table.id;
  const PAGE_SIZE = 100;
  const { chatId } = useParams();
  const [totalCount, setTotalCount] = useState<number | null>(null);

  const collection = `/chats/${chatId}/tables/${tableId}/rows`;
  const chatUpdates = useChatUpdates();
  const plan_update = chatUpdates.find(
    (u) => u.type === "research_plan_complete",
  );
  function updateTotalCount() {
    getCount(collection, PAGE_SIZE).then((count) => setTotalCount(count));
  }
  // Fetch number of pages
  useEffect(() => {
    getCount(collection, PAGE_SIZE).then((count) => setTotalCount(count));
  }, [collection]);

  const totalPages = Math.ceil((totalCount || 1) / PAGE_SIZE);
  const { allRows, getNextPage, getPreviousPage, page, loading } = useTableRows(
    {
      tableId,
      pageSize: PAGE_SIZE,
    },
  );

  // Handle pagination button clicks
  const handlePreviousClick = () => {
    if (loading || page === 1) return;
    getPreviousPage();
  };

  const handleNextClick = () => {
    if (loading || page === totalPages) return;
    getNextPage();
  };
  const downloadHandler = async () => {
    const id = toast.loading("Starting download... Please wait.");

    const tableRows = await getPaginatedData(
      collection,
      "index",
      "next",
      undefined,
      undefined,
      10_000,
    );
    downloadList({
      companyList: tableRows.result
        .map((row) => new TableRow(row))
        .map((c) => c.company),
      currentOpenChatId: chatId,
      columns: table.column_list,
    });
    toast.done(id);
  };
  const rowIds = allRows.map((c) => c.id);
  const companies = allRows.map((c) => c.company);
  const companyLoadingColumns = allRows.map((c) => c.columns_loading || []);

  const alternativeColumns: AltColumn[] = [
    { original: "name", alternative: "company_name" },
    { original: "website", alternative: "website_url" },
    { original: "website", alternative: "company_website" },
    { original: "total_employee_estimate", alternative: "number_of_employees" },
  ];
  // log(table)
  return (
    <TableView
      table={table}
      type="default"
      companies={companies}
      companiesLoading={false}
      handleNextPage={handleNextClick}
      handlePreviousPage={handlePreviousClick}
      hasNextPage={page < (totalCount || Number.POSITIVE_INFINITY)}
      page={page}
      rowCount={totalCount || 1}
      totalPages={totalPages}
      pageSize={PAGE_SIZE}
      columnDetails={
        plan_update?.type === "research_plan_complete" ? plan_update?.data : []
      }
      companyLoadingColumns={companyLoadingColumns}
      column_list={table.column_list}
      columnFilters={table.columnFilters}
      downloadHandler={downloadHandler}
      alternativeColumns={alternativeColumns}
      rowIds={rowIds}
      updateTotalCount={updateTotalCount}
    />
  );
}
