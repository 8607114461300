import { useState, useEffect } from "react";
import {
  getFirestore,
  doc,
  onSnapshot,
  Timestamp,
  getDoc,
} from "firebase/firestore";
import firebase_app from "../firebase/config";
import { Fields } from "src/models/company";
import { Chat, ResearchPlanItem } from "src/models/data";
import { useParams } from "react-router-dom";
import { CustomTool, KurationTool } from "src/models/tools";

type ResearchRunStatus = "paused" | "running" | "completed";
type ToolRunStatus = "paused" | "pausing" | "running" | "completed";

export class Recipe {
  chat: Chat;
  table: Table;
  columns: (KurationToolColumn | CustomToolColumn)[];
  constructor({
    chat,
    table,
    columns,
  }: {
    chat: Chat;
    table: Table;
    columns: (KurationToolColumn | CustomToolColumn)[];
  }) {
    this.chat = chat;
    this.table = table;
    this.columns = columns;
  }
}

export interface KurationToolColumn {
  id: string;
  type: "tool_column";
  data_field: string;
  timestamp: Timestamp;
  hidden?: boolean;
  column_data: { [key: string]: string };
  tool: KurationTool;
  run_status?: ToolRunStatus;
  run_status_reason?: "credits" | "user";
  run_count?: number;
  sort: number;
  display_name?: string;
  deleted: boolean;
  conditions?: Array<{
    display?: string;
    key: string;
    type: string;
    value: string | boolean | number;
  }>;
}

export interface CustomToolColumn {
  id: string;
  type: "custom_tool_column";
  data_field: string;
  timestamp: Timestamp;
  hidden?: boolean;
  custom_tool: Omit<CustomTool, "id">;
  custom_tool_id: string;
  run_status_reason?: "credits" | "user";
  run_status?: ToolRunStatus;
  sort: number;
  display_name?: string;
  deleted: boolean;
  run_count?: number;
  conditions?: Array<{
    display?: string;
    key: string;
    type: string;
    value: string | boolean | number;
  }>;
}

interface ResearchPlanColumn {
  id: string;
  type: "research_plan_column";
  sort: number;
  display_name?: string;
  deleted: boolean;
  data_field: string;
  research_plan_item: ResearchPlanItem;
  timestamp: Timestamp;
  hidden: boolean;
}

interface NormalColumn {
  id: string;
  sort: number;
  display_name?: string;
  deleted: boolean;
  type: "normal";
  timestamp: Timestamp;
  data_field: string;
  hidden: boolean;
}

export type Column =
  | KurationToolColumn
  | NormalColumn
  | ResearchPlanColumn
  | CustomToolColumn;

export class Table {
  id: string;
  public: boolean;
  source:
    | "manual_search_result"
    | "user_uploaded_list"
    | "pre_curated_list"
    | "ai_generated";
  search_filter?: Fields;
  column_list: Column[];
  run_status?: ResearchRunStatus;
  run_status_reason?: "credits" | "user";
  columnFilters?: any[]; // New property for column filters

  constructor({
    id,
    is_public,
    source,
    search_filter,
    columns,
    run_status,
    run_status_reason,
    columnFilters, // Accept columnFilters in the constructor
  }: {
    id: string;
    is_public: boolean;
    source:
      | "manual_search_result"
      | "user_uploaded_list"
      | "pre_curated_list"
      | "ai_generated";
    search_filter?: Fields;
    columns?: { [key: string]: Column };
    run_status?: ResearchRunStatus;
    run_status_reason?: "credits" | "user";
    columnFilters?: any[]; // Accept columnFilters in the constructor
  }) {
    this.id = id;
    this.public = is_public;
    this.column_list = Object.values(columns || {})
      .filter((c) => c.id)
      .filter((c) => !c.deleted)
      .sort(
        (a, b) =>
          a.timestamp.toDate().getTime() - b.timestamp.toDate().getTime(),
      )
      .sort((a, b) => a.sort - b.sort);
    this.search_filter = search_filter;
    this.source = source;
    this.run_status = run_status;
    this.run_status_reason = run_status_reason;
    this.columnFilters = columnFilters || []; // Initialize columnFilters
  }
}

const useDefaultTable = (tableId: string | null | undefined) => {
  const { chatId } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Table | null>(null);

  useEffect(() => {
    setLoading(true);
    if (!chatId || !tableId) {
      setData(null);
      setLoading(false);
      return;
    }

    // Create a reference to the Firestore collection for the default table
    const defaultTableRef = doc(
      getFirestore(firebase_app),
      `chats/${chatId}/tables`,
      tableId,
    );

    const unsubscribeTable = onSnapshot(
      defaultTableRef,
      async (snapshot) => {
        if (snapshot.exists()) {
          const tableData = snapshot.data();
          tableData.id = snapshot.id;

          // Create a new Table instance with columnFilters from tableData
          setData(new Table({ ...tableData, columnFilters: tableData.column_filter } as any));
          setLoading(false);
        } else {
          setData(null);
          setLoading(false);
        }
      },
      (error) => {
        console.log(error);
        setLoading(false);
        setData(null);
      },
    );

    return () => {
      unsubscribeTable();
    };
  }, [chatId, tableId]);
  console.log("da==============================================ta", data);
  return { loading, data };
};

export default useDefaultTable;

export async function getTableDetails(chatId: string, tableId: string) {
  if (!chatId || !tableId) {
    return null;
  }
  const defaultTableRef = doc(
    getFirestore(firebase_app),
    `chats/${chatId}/tables`,
    tableId,
  );
  const docSnap = await getDoc(defaultTableRef);
  if (docSnap.exists()) {
    return new Table(docSnap.data() as any);
  } else {
    return null;
  }
}
