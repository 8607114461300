import React, { useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Link, useNavigate } from "react-router-dom";
import KurationSmallIcon from "../../components/svgs/KurationSmall";
import PlusIcon from "../../components/svgs/Plus";
import TableIcon from "../../components/svgs/Table";
import ThreeDotsIcon from "../../components/svgs/ThreeDots";
import HistoryIcon from "../../components/svgs/History";
import WalletIcon from "../../components/svgs/Wallet";
import StarIcon from "../../components/svgs/Star";
import { TopChats } from "./TopChats";
import ProfileCard from "./ProfileCard";

import useAllChats from "../../hooks/useAllChats";
import { NoChatsBox } from "./NoChatsBox";
import { IconBox } from "../../components/elements/IconBox";
import { createPortalLink, loadCheckout } from "../../components/utils";
import Spinner, { SimpleSpinner } from "../../components/elements/Spinner";
import { useAuth } from "../../context/AuthContext";
import { IconButton } from "../../components/elements/IconButton";
import { ProfilePicture } from "../../components/elements/ProfilePicture";
import { useClickOutside } from "../../hooks/useClickOutside";
import { useStateContext } from "../../context/StateContext";
import useUserCredits from "../../hooks/useUserCredits";
import { useTutorialMode } from "../../hooks/useTutorialMode";
import KurationLogo from "src/components/svgs/KurationLogo";
import useUserSubscriptions from "src/hooks/useUserSubscriptions";

const Sidebar = () => {
  const [showAllChats, setShowAllChats] = useState(false);
  const { user } = useAuth();
  const { loading: chatsLoading, data: userChats } = useAllChats(
    user?.uid ?? null,
  );
  const ref = useRef<HTMLDivElement>(null);
  const { setTutorialOpen } = useTutorialMode();
  const {
    setSidebarOpen,
    sidebarOpen,
    setChatType,
    setChatOpen,
    setFiltersOpen,
    setDpeState,
  } = useStateContext();
  const navigator = useNavigate();

  function closeSidebarOnTabs() {
    if (window.innerWidth < 770) {
      setSidebarOpen(false);
    }
  }
  function openNewChat(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.currentTarget.blur();
    setDpeState({isOpen: false, type: "null_dpe"});
    setChatOpen(true);
    setFiltersOpen(false);
    setChatType("refine");
    setSidebarOpen(false);
    setTutorialOpen(false);
    closeSidebarOnTabs();
    navigator("/");
  }
  useClickOutside(ref, (e) => {
    closeSidebarOnTabs();
  });

  return (
    <aside
      className={`group z-50 flex flex-col justify-between overflow-y-auto border-grey-400 bg-white text-dark transition-all duration-300 ease-out md:z-50 md:h-screen md:border-r `}
      ref={ref}
    >
      <nav className="visible hidden h-full  max-w-[94px] flex-col gap-0  opacity-100 transition-all duration-300 ease-out group-hover:invisible  group-hover:opacity-0 md:flex">
        <div className="flex w-[full] items-center justify-center px-8 py-9">
          <KurationSmallIcon />
        </div>
        <div className="flex flex-grow flex-col px-6 pb-8 pt-4">
          <div className="mb-6">
            <IconBox icon={<PlusIcon />} isPrimary />
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col gap-2">
              <IconBox icon={<TableIcon />} />
              <IconBox icon={<ThreeDotsIcon />} />
              <div className="my-4  h-[1px] bg-grey-400"></div>
              <IconBox icon={<HistoryIcon />} />
            </div>
          </div>
          <div className="mt-auto flex flex-col gap-6">
            <IconBox icon={<WalletIcon />} />
            <ProfilePicture dimensions={48} shadow={false} />
          </div>
        </div>
      </nav>
      <nav
        className={`absolute bottom-0 top-0 z-50 m-0 flex  max-w-[293px] -translate-x-full flex-col border-r  border-grey-400 bg-white opacity-0 transition-all duration-300 ease-out group-focus-within:translate-x-0 group-focus-within:opacity-100 group-hover:visible group-hover:translate-x-0 group-hover:opacity-100 md:max-w-[268px] 
        ${
          sidebarOpen
            ? "visible translate-x-0 opacity-100 md:invisible md:group-focus-within:translate-x-0 md:group-focus-within:opacity-100"
            : "flex md:group-focus-within:translate-x-0 md:group-focus-within:opacity-100"
        }`}
      >
        <Link
          to={"/"}
          className="hidden w-[full] items-center  justify-center px-8 py-9 md:flex"
        >
          <KurationLogo className="w-full" height={38} />
        </Link>
        <div className="px-6 py-4 ">
          <button
            className="flex h-12 active:scale-90 transition-all duration-300 w-full items-center justify-center gap-1.5 rounded-md  bg-primary px-4 text-base leading-[19.2px] text-white hover:bg-purpleHover"
            onClick={(e) => {
              openNewChat(e);
            }}
            title="open new chat request"
          >
            <div>
              <PlusIcon />
            </div>
            <div>New Request</div>
          </button>
        </div>
        <div className="flex flex-grow  overflow-y-auto px-6 pb-4 ">
          {chatsLoading ? (
            <Spinner
              text="Loading you previous chats."
              onWhiteBackground={true}
            />
          ) : (
            <div className="m-0 w-56 flex-grow flex-col  p-0 md:w-52">
              {userChats?.length && userChats?.length > 0 ? (
                <div className="flex flex-grow flex-col gap-6">
                  <TopChats userChats={userChats} showAllChats={showAllChats} />
                  <IconButton
                    variant="outline"
                    icon={<HistoryIcon className="fill-purpleHover" />}
                    text={`${showAllChats ? "Hide all" : "All"} projects`}
                    onClick={() => {
                      setChatType("refine");
                      setShowAllChats(!showAllChats);
                    }}
                  />
                </div>
              ) : (
                <NoChatsBox />
              )}
            </div>
          )}
        </div>
        <div className="mt-auto flex flex-col gap-4 px-6 py-4">
          <CreditsLeft />
          <ProfileCard />
        </div>
      </nav>
    </aside>
  );
};
export default Sidebar;

function CreditsLeft() {
  const { user } = useAuth();
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [portalLoading, setPortalLoading] = useState(false);

  const { userCredits } = useUserCredits(user?.uid ?? null);
  const { data: subscriptionsData } = useUserSubscriptions();

  function handleCustomerPortalOpen() {
    createPortalLink(setPortalLoading);
  }
  function handleBuyCredit() {
    loadCheckout(user?.uid ?? "", setPaymentLoading);
  }
  return (
    <div className="relative flex flex-col gap-4 rounded-2xl border border-purpleHover px-2.5 py-4">
      <div
        className={twMerge(
          "flex flex-col gap-2.5",
          `${paymentLoading || portalLoading ? "invisible" : ""}`,
        )}
      >
        <div className="relative h-[6px] w-full rounded-md bg-grey-500">
          <div
            className="absolute left-0 h-[6px]  w-full rounded-md bg-success"
            style={{
              width: `${(userCredits?.col_credits || 1) > 1_000 ? "90" : "10"}%`,
            }}
          ></div>
        </div>
        <div className="text-lg font-normal leading-[25.2px] text-black">
          {!!userCredits
            ? `${Intl.NumberFormat("en", { notation: "compact" }).format(
                userCredits.col_credits,
              )}`
            : "..."}{" "}
          credits left
        </div>
      </div>
      {subscriptionsData.length > 0 ? (
        <button
          onClick={() => {
            handleCustomerPortalOpen();
          }}
          className={twMerge(
            "flex cursor-pointer items-center justify-center gap-2 py-1 text-purpleHover",
            `${paymentLoading || portalLoading ? "invisible" : ""}`,
          )}
        >
          <div>Manage subscriptions</div>
          <div>
            <StarIcon />
          </div>
        </button>
      ) : (
        <button
          onClick={() => {
            handleBuyCredit();
          }}
          className={twMerge(
            "flex cursor-pointer items-center justify-center gap-2 py-1 text-purpleHover",
            `${paymentLoading || portalLoading ? "invisible" : ""}`,
          )}
        >
          <div>Buy more credits</div>
          <div>
            <StarIcon />
          </div>
        </button>
      )}
      {(paymentLoading || portalLoading) && (
        <div className="absolute inset-0 flex items-center justify-center gap-2 ">
          <SimpleSpinner
            radius={20}
            overrideClasses="border-2 border-purpleHover"
          />
          <div className="text-sm text-purpleHover">
            Loading {portalLoading ? "" : "Checkout"}
          </div>
        </div>
      )}
    </div>
  );
}
