import { useState } from "react";
import BackIcon from "../svgs/Back";
import CloseIcon from "../svgs/CloseIcon";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "src/context/AuthContext";
import { SimpleSpinner } from "../elements/Spinner";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { KurationColumnForm } from "./ColumnForm";
import { ToolsList } from "./ToolsList";
import { fetchTools } from "../utils";
import { KurationTool } from "src/models/tools";
import { Column } from "src/hooks/useChatTable";
import { FreeDict } from "src/models/data";

export default function ColumnAdder({
  onClose,
  onSuccess,
  columns,
  // if true, the column creation will be prevented
  preventColumnCreation,
}: {
  onClose: () => void;
  onSuccess: ({
    tool,
    args,
    conditions,
  }: {
    tool: KurationTool;
    args: FreeDict;
    conditions: FreeDict[];
  }) => void;
  columns: Column[];
  // if true, the column creation will be prevented
  preventColumnCreation: boolean;
}) {
  const [selectedTool, setSelectedTool] = useState<KurationTool | null>(null);
  return (
    <div
      className="max-h-screen  overflow-auto rounded-2xl bg-white p-9"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Head
        onClose={onClose}
        selectedTool={selectedTool}
        setSelectedTool={setSelectedTool}
      />
      <Body
        onSuccess={onSuccess}
        selectedTool={selectedTool}
        setSelectedTool={setSelectedTool}
        columns={columns}
        preventColumnCreation={preventColumnCreation}
      />
    </div>
  );
}

const Head = ({
  onClose,
  selectedTool,
  setSelectedTool,
}: {
  selectedTool: KurationTool | null;
  setSelectedTool: React.Dispatch<React.SetStateAction<KurationTool | null>>;
  onClose: () => void;
}) => {
  return (
    <div className="flex w-full items-center justify-between border-b border-grey-400 pb-6 text-lg">
      {selectedTool && (
        <button
          type="button"
          title="back to table"
          className="flex h-[42px] w-[42px] items-center justify-center rounded-md transition-all duration-300 hover:bg-lightpurple"
          onClick={() => {
            setSelectedTool(null);
          }}
        >
          <BackIcon />
        </button>
      )}
      <div className="pl-1">
        {selectedTool ? `Create ${selectedTool.name} column` : "Select a tool"}
      </div>
      <div className="flex-1"></div>
      <button
        type="button"
        title="Close Add Column Menu"
        className="flex h-[42px] w-[42px] items-center justify-center rounded-md transition-all duration-300 hover:bg-lightpurple"
        onClick={() => {
          onClose();
        }}
      >
        <CloseIcon />
      </button>
    </div>
  );
};
const Body = ({
  onSuccess,
  selectedTool,
  setSelectedTool,
  columns,
  preventColumnCreation,
}: {
  setSelectedTool: React.Dispatch<React.SetStateAction<KurationTool | null>>;
  selectedTool: KurationTool | null;
  onSuccess: ({
    tool,
    args,
    conditions,
  }: {
    tool: KurationTool;
    args: FreeDict;
    conditions: FreeDict[];
  }) => void;
  preventColumnCreation: boolean;
  columns: Column[];
}) => {
  const { user } = useAuth();
  const {
    isLoading,
    error,
    data: tools,
    refetch,
  } = useQuery({
    queryKey: ["tools"],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return user && fetchTools(user?.getIdToken());
    },
  });

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center gap-2 py-5">
        <SimpleSpinner radius={20} overrideClasses="border-black" />
        loading..
      </div>
    );
  } else if (error || !Array.isArray(tools)) {
    return (
      <div className="flex h-full items-center justify-center ">
        <button
          className="flex items-center gap-2"
          onClick={() => {
            refetch();
          }}
        >
          <ArrowPathIcon width={20} className="text-black" />
          <div className="text-black">Retry</div>
        </button>
      </div>
    );
  }
  return (
    <div>
      <div>
        {!selectedTool ? (
          <ToolsList tools={tools} setSelectedTool={setSelectedTool} />
        ) : (
          <KurationColumnForm
            selectedTool={selectedTool}
            columnsFallback={columns}
            onSuccess={onSuccess}
            preventColumnCreation={preventColumnCreation}
          />
        )}
      </div>
    </div>
  );
};
