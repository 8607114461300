import { useAuth } from "src/context/AuthContext";
import useAllChats from "../../hooks/useAllChats";
import { SimpleSpinner } from "src/components/elements/Spinner";
import {
  CustomToolColumn,
  getTableDetails,
  KurationToolColumn,
  Recipe,
  Table,
} from "src/hooks/useChatTable";
import { Chat } from "src/models/data";
import { useEffect, useState } from "react";
import { ToolIcon } from "src/components/table/tableUtils";
import { useParams } from "react-router-dom";
import { EnrichmentLibraryCard } from "./FilterCard";
import { IconButton } from "src/components/elements/IconButton";
import { PlusIcon } from "@heroicons/react/24/outline";

export default function RecipeList({
  setSelectedRecipe,
}: {
  setSelectedRecipe: (recipe: Recipe) => void;
}) {
  const { user } = useAuth();
  const { data: chats, loading } = useAllChats(user?.uid ?? null);
  const { chatId } = useParams();
  const [hiddenChats, setHiddenChats] = useState<string[]>([]);
  function hideChat(chat_id: string) {
    setHiddenChats(Array.from(new Set([...hiddenChats, chat_id])));
  }
  const [page, setPage] = useState(1);
  if (loading)
    return (
      <div>
        <SimpleSpinner radius={20} overrideClasses="border-dark" />
      </div>
    );
  if (chats?.length < 1) {
    return <div>No recipes yet... Create a new chat to get started</div>;
  }
  return (
    <div>
      <p className="mb-4  text-lg">Columns you have in your other chats... </p>
      <div className="grid grid-cols-2 gap-4">
        {chats
          .filter(
            (chat) =>
              chat.chat_id !== chatId &&
              chat.table_id &&
              !hiddenChats.includes(chat.chat_id),
          )
          .slice(0, page * 10)
          .map((chat) => {
            console.log(chat);
            return (
              <RecipeListRow
                key={chat.chat_id}
                chat={chat}
                hideChat={hideChat}
                onSelect={setSelectedRecipe}
              />
            );
          })}
      </div>
      <div className="flex justify-center">
        <IconButton
          overrideClasses="w-30 mt-8"
          icon={<PlusIcon className="h-4 w-4 text-primary" />}
          text="Load More"
          variant="outline"
          onClick={() => setPage(page + 1)}
        />
      </div>
    </div>
  );
}

function RecipeListRow({
  chat,
  hideChat,
  onSelect,
}: {
  chat: Chat;
  hideChat: (chat_id: string) => void;
  onSelect: (recipe: Recipe) => void;
}) {
  const [tableDetails, setTableDetails] = useState<{
    loading: boolean;
    error: string;
    table: Table | null;
  }>({ loading: true, error: "", table: null });
  
  useEffect(() => {
    if (!chat.table_id) {
      hideChat(chat.chat_id);
      return;
    }
    if (chat.table_id) {
      getTableDetails(chat.chat_id, chat.table_id).then((table) => {
        if (
          table &&
          table.column_list.some(
            (column) =>
              column.type === "custom_tool_column" ||
              column.type === "tool_column",
          )
        ) {
          setTableDetails({ loading: false, error: "", table: table });
        } else {
          hideChat(chat.chat_id);
        }
      });
    }
  }, [chat.chat_id, chat.table_id, hideChat]);

  if (!tableDetails.table || tableDetails.loading) {
    return null;
    // return <SimpleSpinner radius={20} overrideClasses="border-dark" />;
  }

  if (tableDetails.error) {
    return <div>Error: {tableDetails.error}</div>;
  }
  const cols =
    (tableDetails.table?.column_list.filter(
      (column) =>
        column.type === "custom_tool_column" || column.type === "tool_column",
    ) || []) as (KurationToolColumn | CustomToolColumn)[];
  const topRow = (
    <div className="flex flex-row items-center gap-1 overflow-hidden">
      {cols?.slice(0, 5).map((column) => (
        <div key={column.id}>
          <ToolIcon col={column as KurationToolColumn | CustomToolColumn} />
        </div>
      ))}
      {cols?.length > 5 && (
        <div className="ml-2 text-xs text-gray-600">+{cols.length - 5}</div>
      )}
    </div>
  );
  const bottomRow = chat.chat_name || chat.chat_id;
  const table = tableDetails.table;
  if (!table) {
    return null;
  }
  return (
    <div
      className="animate-fade"
      onClick={() =>
        onSelect(
          new Recipe({
            chat: chat,
            table: table,
            columns: cols,
          }),
        )
      }
    >
      <EnrichmentLibraryCard topRow={topRow} bottomRow={bottomRow} />
    </div>
  );
}
