import { useState } from "react";
import { useParams } from "react-router-dom";
import { SimpleSpinner } from "src/components/elements/Spinner";
import { FilterTags } from "./FilterTags";
import { FilterCard } from "./FilterCard";
import { CustomTool, KurationTool, Tool } from "src/models/tools";
import { CustomFiltersList } from "./CustomFiltersList";
import { ColumnConditions } from "../../../src/components/columnAdder/ColumnForm";
import useDefaultTable, { Recipe } from "../../../src/hooks/useChatTable";
import useChatDetails from "../../../src/hooks/useChatDetails";
import { FilterFormField } from "../../../src/components/preCuratedLists/FilterFields";
import RecipeList from "./RecipeList";

export const FiltersMain = ({
  isLoading,
  error,
  tools,
  retryTools,
  customTools,
  customToolsLoading,
  setSelectedTool,
  setShowCreateCustomFilter,
  filterForm,
  setFilterForm,
  setSelectedRecipe,
}: {
  isLoading: boolean;
  error: any;
  tools: KurationTool[];
  customToolsLoading: boolean;
  customTools: CustomTool[];
  retryTools: () => void;
  setShowCreateCustomFilter: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTool: React.Dispatch<React.SetStateAction<Tool | null>>;
  filterForm: FilterFormField[];
  setFilterForm: React.Dispatch<React.SetStateAction<FilterFormField[]>>;
  setSelectedRecipe: React.Dispatch<React.SetStateAction<Recipe | null>>;
}) => {
  const [filter, setFilter] = useState<
    "all" | "kuration" | "custom" | "history"
  >("all");
  const [showListFilters, setShowListFilters] = useState(false); // can add it back later
  const { chatId } = useParams();
  const { chatDetails } = useChatDetails(chatId ?? null);
  const tableId = chatDetails?.table_id;
  const { data: defaultTable } = useDefaultTable(tableId);
  return (
    <>
      {showListFilters ? (
        <ColumnConditions
          columns={defaultTable?.column_list || []}
          filterForm={filterForm}
          setFilterForm={setFilterForm}
          runFilter={true}
        />
      ) : (
        <>
          <div className="inline-flex flex-col items-start justify-start gap-[33px] border-b border-neutral-300 bg-white px-6 py-9">
            <div className="text-lg font-normal text-black">
              Enrichment library
            </div>
            <div className="inline-flex items-center justify-start gap-3">
              <div className="flex flex-wrap items-center justify-start gap-4">
                <div className="text-neutral-400">Jump to:</div>
                <FilterTags
                  selected={filter === "all"}
                  text="All"
                  onClick={() => setFilter("all")}
                />
                <FilterTags
                  selected={filter === "kuration"}
                  onClick={() => setFilter("kuration")}
                  text="Kuration AI enrichment library"
                />
                <FilterTags
                  selected={filter === "custom"}
                  onClick={() => setFilter("custom")}
                  withStars
                  text="Your own filters"
                />
                <FilterTags
                  selected={filter === "history"}
                  onClick={() => setFilter("history")}
                  text="My recipes"
                />
              </div>
            </div>
          </div>

          <div className="w-full p-6">
            {(filter === "all" || filter === "custom") && (
              <CustomFiltersList
                customTools={customTools}
                customToolsLoading={customToolsLoading}
                setShowCreateCustomFilter={setShowCreateCustomFilter}
                setSelectedTool={setSelectedTool}
              />
            )}
            {filter === "history" && (
              <RecipeList setSelectedRecipe={setSelectedRecipe} />
            )}

            {(filter === "all" || filter === "kuration") && (
              <div className="flex flex-col gap-6">
                <div className="flex w-full items-center justify-between gap-4">
                  <div className="text-lg font-normal text-neutral-900">
                    Kuration AI enrichment library ready-made filters
                  </div>
                  <div className="flex min-w-max items-center justify-center gap-2.5 rounded bg-indigo-50 px-3 py-2.5">
                    <div className="text-neutral-900">
                      {tools?.length || 0} filters
                    </div>
                  </div>
                </div>

                <div
                  className="flex h-[110px] max-w-[248px] cursor-pointer flex-col items-start justify-between gap-6 rounded-md border-b-4 border-neutral-100 bg-neutral-100 px-3 py-4 transition-all duration-300 hover:border-purple"
                  onClick={() => setShowListFilters(true)} // Make the whole div clickable
                >
                  <div className="inline-flex h-6 w-6 items-center justify-center px-[1.49px] pb-[2.25px] pt-[2.12px]">
                    <div className="relative">
                      <img
                        className="h-[19.63px] w-[21.02px]"
                        src="/curator-tool.png"
                        alt="Icon"
                      />
                    </div>
                  </div>
                  <div className="h-min w-full text-neutral-900">Curator</div>
                </div>

                {isLoading && <SimpleSpinner />}
                {(!!error || !Array.isArray(tools)) && (
                  <div onClick={() => retryTools()}>Retry</div>
                )}
                <div className="grid grid-cols-2 gap-4">
                  {(tools as KurationTool[])?.map((t) => (
                    <div key={t.id} onClick={() => setSelectedTool(t)}>
                      <FilterCard name={t.name} iconUrl={t.icon} />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
