// StateContext.js
import React, { createContext, useContext, useState } from "react";
import { AppState, ChatType } from "../models/appState";
import { DpeState } from "src/models/Dpe";

// Extend AppState to include remainingRows and filterWiseRows
interface AppStateWithRemainingRows extends AppState {
  remainingRows: number | null; 
  setRemainingRows: (count: number) => void; 
  filterWiseRows: Record<string, number>; 
  setFilterWiseRows: (filterRows: Record<string, number>) => void; 
}

const StateContext = createContext<AppStateWithRemainingRows>({
  chatOpen: true,
  setChatOpen: () => {},
  filtersOpen: true,
  setFiltersOpen: () => {},
  sidebarOpen: window.innerWidth > 770,
  setSidebarOpen: () => {},
  chatType: "refine",
  setChatType: (value) => {},
  dpeState: { isOpen: false, type: "null_dpe" },
  setDpeState: () => {},
  remainingRows: null, 
  setRemainingRows: () => {}, 
  filterWiseRows: {}, 
  setFilterWiseRows: () => {}, 
});

export const StateProvider = ({ children }: { children: React.ReactNode }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [chatOpen, setChatOpen] = useState(true);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [chatType, setChatType] = useState<ChatType>("refine");

  const [dpeState, setDpeState] = useState<DpeState>({
    isOpen: false,
    type: "null_dpe"
  });
  const [remainingRows, setRemainingRows] = useState<number | null>(null);
  const [filterWiseRows, setFilterWiseRows] = useState<Record<string, number>>({});

  return (
    <StateContext.Provider
      value={{
        chatOpen,
        setChatOpen,
        sidebarOpen,
        setSidebarOpen,
        chatType,
        setChatType,
        filtersOpen,
        setFiltersOpen,
        dpeState,
        setDpeState,
        remainingRows,       
        setRemainingRows,     
        filterWiseRows,       
        setFilterWiseRows,    
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => {
  return useContext(StateContext);
};
