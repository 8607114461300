import CloseIcon from "src/components/svgs/CloseIcon";
import { useStateContext } from "src/context/StateContext";
import { DpeState } from "src/models/Dpe";
import { LangSmithDpe } from "./LangsmithDpe";
import { ToolDpe } from "./ToolDpe";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { MarkDown } from "src/components/elements/Markdown";
import { getColumnDisplayName } from "src/components/utils";
import { ColNameWithIcon } from "src/components/table/tableUtils";

export function DataPointExplorer({ dpeState }: { dpeState: DpeState }) {
  const { setDpeState } = useStateContext();
  if (!dpeState.isOpen) {
    return <div>Select a cell to open Data point explorer.</div>;
  }
  // console.log(dpeState);
  return (
    <div className="blue-links">
      <div className="border-b border-grey-400 px-6 pb-10 pt-9">
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-2">
            <h4 className="flex  flex-1 gap-2 text-xl font-normal">
              <span>Cell Details</span>
              {(dpeState.cell.type === "custom_column" ||
                dpeState.cell.type === "final_researched") &&
                !dpeState.cell.free && (
                  <CheckCircleIcon className="text-green-400" width={20} />
                )}
            </h4>

            <button
              type="button"
              title="Close cell details"
              className="flex h-[42px] w-[42px] items-center justify-center rounded-md transition-all duration-300 hover:bg-lightpurple"
              onClick={() => {
                setDpeState({ isOpen: false, type: "null_dpe" });
              }}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="flex gap-2 items-center">
            {typeof dpeState.column === "string" ? (
              dpeState.column
            ) : dpeState.column.type === "custom_tool_column" ||
              dpeState.column.type === "tool_column" ? (
              <ColNameWithIcon col={dpeState.column} />
            ) : (
              <div>{getColumnDisplayName(dpeState.column)}</div>
            )}
          </div>
          <div className="text-grey-200 ">
            <MarkDown>{dpeState.cell.value || " _ "}</MarkDown>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 px-6 pb-10 pt-9">
        {dpeState.type === "langsmith_dpe" ? (
          <LangSmithDpe dpeState={dpeState} />
        ) : dpeState.type === "kuration_tool_dpe" ? (
          <ToolDpe dpeState={dpeState} />
        ) : (
          <div>Unable to show data point explorer window</div>
        )}
      </div>
    </div>
  );
}
