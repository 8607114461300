import { InitialListItem } from "src/models/InitialList";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import backend_services from "src/services/backend_service";
import Spinner from "../elements/Spinner";
import { toast } from "react-toastify";
import { useAuth } from "src/context/AuthContext";
import log from "../utils";
import SearchIcon from "../svgs/Search";

import CloseIcon from "../svgs/CloseIcon"; // Assuming CloseIcon is used in this component

interface IndeedJobScraperProps {
  item: InitialListItem; // Define the item prop
  handleClose: () => void; // Define the handleClose function prop
}

const IndeedJobScraper: React.FC<IndeedJobScraperProps> = ({
  item,
  handleClose,
}) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const [location, setLocation] = useState("");
  const [pages, setPages] = useState("1"); // Updated to track pages instead of number of results
  const navigate = useNavigate();

  async function handleSearch() {
    try {
      setLoading(true);
      if (user) {
        const result = await backend_services
          .fetchProtectedData(
            `/initial_lists/indeedJob`,
            user.getIdToken(),
            undefined,
            "POST",
            {
              job_title: jobTitle,
              location: location,
              pages: parseInt(pages), // Send the number of pages instead of results
            },
          )
          .then((res) => {
            setLoading(false);
            return res.json();
          });

        if (result.data && typeof result.data.chat_id === "string") {
          navigate(`/chat/${result.data.chat_id}`);
        } else if (result.message) {
          toast.error(result.message);
        } else if (result.detail) {
          toast.error(result.detail);
        }
      }
    } catch (error) {
      log(error);
      setLoading(false);
      toast.error("Something went wrong. Please try again.");
    }
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative rounded-lg bg-white p-6 shadow-lg">
        {loading && <Spinner text="Searching..." />}
        <div className="flex flex-col gap-5">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-bold">Indeed Job Scraper</h2>
            <button onClick={handleClose}>
              <CloseIcon />
            </button>
          </div>
          <hr className="w-full" />

          <div className="flex flex-col gap-2">
            <label>Job Title</label>
            <input
              type="text"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              placeholder="e.g. Software Engineer"
              className="rounded border p-2"
            />
          </div>

          <div className="flex flex-col gap-2">
            <label>Location</label>
            <input
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              placeholder="e.g. New York, USA"
              className="rounded border p-2"
            />
          </div>

          <div className="flex flex-col gap-2">
            <label>Number of Pages</label> {/* Updated label */}
            <input
              type="number"
              value={pages}
              onChange={(e) => setPages(e.target.value)} // Changed to setPages
              placeholder="1" // Placeholder for number of pages
              className="rounded border p-2"
            />
          </div>

          <div className="flex items-center justify-between">
            <span className="text-sm">1 credit for 1 page of job results</span>
            <button
              onClick={handleSearch}
              className="flex items-center gap-2 rounded bg-purple p-2 text-white"
            >
              <span>Search</span>
              <SearchIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndeedJobScraper;
