import { Recipe } from "src/hooks/useChatTable";
import { ColNameWithIcon } from "src/components/table/tableUtils";
import useChatDetails from "src/hooks/useChatDetails";
import { useParams } from "react-router-dom";
import useDefaultTable from "src/hooks/useChatTable";
import { useState } from "react";
import { IconButton } from "src/components/elements/IconButton";
import { PlusIcon } from "@heroicons/react/24/outline";
import backend_services from "src/services/backend_service";
import { useAuth } from "src/context/AuthContext";
import { SimpleSpinner } from "src/components/elements/Spinner";

export function RecipeDetails({ recipe }: { recipe: Recipe }) {
  const { chatId } = useParams();
  const { chatDetails } = useChatDetails(chatId ?? null);
  const tableId = chatDetails?.table_id;
  const { data: defaultTable } = useDefaultTable(tableId ?? null);
  const columns = defaultTable?.column_list || [];
  console.log("recipe", columns);
  const { user } = useAuth();
  const [importRecipeLoading, setImportRecipeLoading] = useState(false);
  function importRecipe() {
    console.log("recipe", recipe);
    setImportRecipeLoading(true);
    try {
      backend_services
        .fetchProtectedData(
          `/import_recipe?chat_id=${chatId}&recipe_chat_id=${recipe.chat.chat_id}`,
          user?.getIdToken(),
          undefined,
          "POST",
          {},
        )
        .then((res) => {
          console.log("res", res);
        })
        .finally(() => {
          setImportRecipeLoading(false);
        });
    } catch (error) {
      console.error("Error importing recipe", error);
    }
  }
  return (
    <div>
      <div className="mb-8 text-xl">
        You can import the following {recipe.columns.length} columns from{" "}
        <strong>{recipe.chat.chat_name || recipe.chat.chat_id}</strong>. They will automagically be added and updated for this table.
      </div>
      <div className="flex flex-col gap-4 max-h-[calc(100dvh-420px)] overflow-y-auto">
        {recipe.columns.map((column) => {
          return (
            <div className="flex flex-col gap-4 rounded-md bg-gray-100 px-4 py-6">
              <div className="flex items-center gap-2">
                <ColNameWithIcon col={column} />
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex justify-center mt-4">
        {importRecipeLoading ? (
          <div className="flex items-center justify-center">
            <SimpleSpinner radius={10} overrideClasses="border-black" />
          </div>
        ) : (
          <IconButton
            icon={<PlusIcon className="h-4 w-4 text-white" />}
            onClick={() => {
              console.log("recipe", recipe);
              importRecipe();
            }}
            text={"Import Recipe to this Table"}
            variant={"fill"}
          ></IconButton>
        )}
      </div>
    </div>
  );
}
