// Dropdown.tsx
import React, { useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { useClickOutside } from "../../hooks/useClickOutside";
import CustomModal from "./CustomModel";

interface DropdownProps {
  options: React.ReactElement[];
  opener: React.ReactElement<HTMLDivElement>;
  overrideClasses?: string;
  classNames?: string;
  blurBg?: boolean;
  openInModal?: boolean;
  onClose?: () => void;
  preventAutoClose?: boolean;
}
function getOverlayPosition(rect: DOMRect | undefined) {
  const screenRect = document.body.getBoundingClientRect();
  const distanceFromRightCorner = Math.abs((rect?.x || 0) - screenRect.width);

  const collidesWithRightSide =
    distanceFromRightCorner - (rect?.width || 0) < 224;
  const collidesWithLeftSide = (rect?.x || Number.POSITIVE_INFINITY) < 224;
  const collidesWithBottomSide =
    screenRect.height - (rect?.y || Number.POSITIVE_INFINITY) < 100;

  // console.log({ collidesWithBottomSide, collidesWithLeftSide, collidesWithRightSide }, rect, screenRect);

  let overlayPosition: {
    left: number | string;
    right: number | string;
    top: number | string;
    bottom: number | string;
  } = {
    left: (rect?.left || 0) + (rect?.width || 0) - 5,
    right: "auto",
    bottom: "auto",
    top: (rect?.top || 0) + (rect?.height || 0) - (rect?.height || 0) / 2,
  };
  if (collidesWithBottomSide) {
    // console.log('collides with bottom side')
    overlayPosition.top = "auto";
    overlayPosition.bottom = screenRect.height - (rect?.y || 0) - 5;
  }
  if (collidesWithRightSide && !collidesWithLeftSide) {
    //only collides with right side
    overlayPosition.right = distanceFromRightCorner - 10;
    overlayPosition.left = "auto";
  }
  if (collidesWithLeftSide && collidesWithRightSide) {
    // collides with both left and right side
    overlayPosition.left = rect?.left || 0;
  }
  // console.log(overlayPosition);
  return overlayPosition;
}

function CustomDropdown({
  opener,
  options,
  overrideClasses = "",
  openInModal = false,
  blurBg = true,
  classNames = "",
  preventAutoClose = false,
  onClose,
}: DropdownProps) {
  console.log(overrideClasses);
  const [isOpen, setIsOpen] = useState(false);
  const openerRef = useRef<HTMLDivElement>(null);
  const ref = useRef(null);
  function closeDropDown() {
    setIsOpen(false);
  }
  useClickOutside(ref, (e) => {
    if (onClose) {
      onClose();
    }
    if (!preventAutoClose) {
      closeDropDown();
    }
  });
  const [rect, setRect] = useState<DOMRect>();

  //method to update the position of dropdown as the opener moves
  // useEffect(() => {
  //     let interval: NodeJS.Timeout;
  //     let count = 0;
  //     if (isOpen) {
  //         interval = setInterval(() => {
  //             count++;
  //             if (count % 50 === 0) { console.log('getting new position', count); }
  //             if (openerRef && openerRef.current) {
  //                 setRect(openerRef.current.getBoundingClientRect())
  //             }
  //         }, 10);
  //     }
  //     return () => {
  //         clearInterval(interval);
  //     }
  // }, [isOpen]);
  return (
    <div className="relative flex" ref={ref}>
      <div
        ref={openerRef}
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={(e) => {
          setRect(e.currentTarget.getBoundingClientRect());
          setIsOpen(!isOpen);
        }}
        className={twMerge(`cursor-pointer`, classNames)}
      >
        {opener}
      </div>

      {openInModal && options.length > 0 ? (
        <CustomModal
          blurBg={blurBg}
          isOpen={isOpen}
          onClose={function (): void {
            closeDropDown();
          }}
        >
          {getModalContent({
            options,
            rect,
            overrideClasses,
            closeDropdown: closeDropDown,
          })}
        </CustomModal>
      ) : (
        isOpen &&
        options.length > 0 &&
        getModalContent({
          options,
          rect,
          overrideClasses,
          closeDropdown: closeDropDown,
        })
      )}
    </div>
  );
}
//   return (
//     <div className="relative flex" ref={ref}>
//       <div
//         ref={openerRef}
//         id="options-menu"
//         aria-haspopup="true"
//         aria-expanded="true"
//         onClick={(e) => {
//           setRect(e.currentTarget.getBoundingClientRect());
//           setIsOpen(!isOpen);
//         }}
//         className={twMerge(`cursor-pointer`, classNames)}
//       >
//         {opener}
//       </div>

//       {isOpen && options.length > 0 && (
//         <div
//           style={{ ...getOverlayPosition(rect) }}
//           className={twMerge(
//             `fixed z-50 w-auto origin-top-left rounded-lg bg-white p-2.5 shadow-[2px_2px_20px_0px_#00000014]`,
//             overrideClasses,
//           )}
//         >
//           <div
//             role="menu"
//             aria-orientation="vertical"
//             aria-labelledby="options-menu"
//           >
//             {options}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

export default CustomDropdown;

function getModalContent({
  rect,
  overrideClasses,
  options,
  closeDropdown,
}: {
  options: React.ReactElement[];
  overrideClasses?: string;
  rect?: DOMRect;
  closeDropdown: () => void;
}) {
  // console.log("wao options", options);
  return (
    <div
      style={{ ...getOverlayPosition(rect) }}
      className={twMerge(
        `fixed z-50 w-56 origin-top-left rounded-lg bg-white p-2.5 shadow-[2px_2px_20px_0px_#00000014]`,
        overrideClasses,
      )}
    >
      <div
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        {options.map((myOption, index) => (
          <div
            key={index}
            onClick={(e) => {
              if (myOption.props["data-close-dropdown"] === "true")
                closeDropdown();
            }}
          >
            {myOption}
          </div>
        ))}
      </div>
    </div>
  );
}
