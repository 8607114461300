import { useState } from "react";
import { KurationTool } from "src/models/tools";

export function ToolsList({
  tools,
  setSelectedTool,
}: {
  tools: KurationTool[];
  setSelectedTool: React.Dispatch<React.SetStateAction<KurationTool | null>>;
}) {
  // State to hold the search query
  const [searchTerm, setSearchTerm] = useState("");

  // Helper function to render each tool button
  const renderToolButton = (tool: KurationTool) => (
    <button
      key={tool.id}
      onClick={() => {
        setSelectedTool(tool);
      }}
      className="flex w-full cursor-pointer items-center gap-3 rounded p-4 outline-none transition-all duration-300 hover:bg-lightpurple focus-visible:bg-lightpurple"
    >
      <div>
        <img src={tool.icon} alt={tool.name + " icon"} width={30} height={30} />
      </div>
      <div className="text-lg">{tool.name}</div>
    </button>
  );

  // Organize tools by categories
  const categorizedTools = {
    "Data Collection & Profile Enrichment": [
      "google_search",
      "website_finder_runner",
      "email_from_linkedin",
      "phone_number_from_linkedin",
      "linkedin_profile_details",
      "linkedin_company_details",
      "classify_linkedin_expat_status_model",
      "get_departmental_head_count",
      "fetch_and_process_company_info",
      "get_company_industry",
      "detect_website_technologies",
      "detect_chatbot_technologies",
      "builtwith_tech_inspector_info",
      "get_employee_location_breakdown",
      "extract_emails_from_website",
      "domain_ad_history", // Added the missing tool
    ],
    "Data Validation & Classification": [
      "email_validator",
      "email_finder",
      "validate_url",
      "classify_target_audience",
      "classify_website_service_model",
      "check_distributor_status",
      "check_hiring_status",
      "check_on_premise_infrastructure",
      "who_is_remote",
    ],
    "Social Media & Review Analysis": [
      "get_company_trustpilot_url",
      "get_trustpilot_average_rating",
      "get_latest_trustpilot_reviews",
      "extract_twitter_from_domain",
      "extract_facebook_from_domain",
      "extract_linkedin_from_domain",
      "check_company_news",
      "check_company_jobs",
      "check_company_events",
      "check_company_ma",
      "fetch_traffic_report",
    ],
    "Content & Messaging Enhancement": [
      "llm_utility_instruction",
      "llm_utility_instruction2",
    ],
    "Revenue & Financial Analysis": [
      "revenue_stream_analystor",
      "get_annual_revenue",
    ],
    "Specific People Search and Counting": [
      "FolkFinder",
      "email_counter",
      "email_extractor",
    ],
  };

  // Filter tools based on search term
  const filteredTools = Object.entries(categorizedTools).reduce(
    (acc, [category, toolIds]) => {
      const filteredToolIds = toolIds.filter((id) =>
        tools.find(
          (tool) =>
            tool.id === id &&
            tool.name.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );

      if (filteredToolIds.length > 0) {
        acc[category] = filteredToolIds;
      }

      return acc;
    },
    {} as Record<string, string[]>,
  );

  return (
    <div className="relative max-h-[70vh] overflow-auto py-4">
      {/* Sticky search bar */}
      <div className="sticky top-0 z-10 mb-4 bg-white py-2">
        <input
          type="text"
          placeholder="Search tools..."
          className="w-full rounded border p-2"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Render filtered tools under each category */}
      {Object.entries(filteredTools).map(([category, toolIds]) => (
        <div key={category} className="mb-6">
          {/* Category heading */}
          <h2 className="mb-3 text-xl font-semibold">{category}</h2>
          {/* Render the tools in this category */}
          {tools
            .filter((tool) => toolIds.includes(tool.id))
            .map((tool) => renderToolButton(tool))}
        </div>
      ))}

      {/* If no tools found */}
      {Object.keys(filteredTools).length === 0 && (
        <div className="text-center text-gray-500">No tools found</div>
      )}
    </div>
  );
}
