import StarsIcon from "src/components/svgs/Stars";

export const FilterCard = ({
  name,
  iconUrl,
}: {
  name: string;
  iconUrl?: string;
}) => {
  const topRow = iconUrl ? (
    <img className="h-[19.63px] w-[21.02px]" src={iconUrl} alt={name} />
  ) : (
    <div className="relative h-6 w-6">
      <StarsIcon fill="#e08588" />
    </div>
  );
  const bottomRow = name;
  return <EnrichmentLibraryCard topRow={topRow} bottomRow={bottomRow} />;
};
export const EnrichmentLibraryCard = ({
  topRow,
  bottomRow,
}: {
  topRow: React.ReactNode;
  bottomRow: string;
}) => {
  return (
    <div className="cursor-pointer active:scale-90  flex h-[110px] max-w-[248px] flex-col items-start justify-between gap-6 rounded-md border-b-4 border-neutral-100 bg-neutral-100 px-3 py-4 transition-all duration-300  hover:border-purple">
      <div className="inline-flex items-center justify-center px-[1.49px] pb-[2.25px] pt-[2.12px]">
        <div className="relative">{topRow}</div>
      </div>
      <div className="h-min w-full truncate text-neutral-900">{bottomRow}</div>
    </div>
  );
};
