import React, { useState, useEffect } from 'react';
import { FormSelect } from "src/pages/filters/FormSelect";
import { twMerge } from "tailwind-merge";
import { IconButton } from "../elements/IconButton";
import CloseIcon from "../svgs/CloseIcon";
import { useStateContext } from "src/context/StateContext";

export interface FilterFormField {
  id: string;
  name: string;
  key: string;
  type:
    | ""
    | "notEquals"
    | "equals"
    | "startsWith"
    | "exists"
    | "contains"
    | "greaterThan"
    | "lessThan";
  value: string | number | boolean;
}

export function getUpdatedForm({
  prev,
  updatedField,
  k,
  v,
}: {
  prev: FilterFormField[];
  updatedField: FilterFormField;
  k: keyof FilterFormField;
  v: string | number | boolean;
}): FilterFormField[] {
  return prev.map((f) => {
    if (f.id === updatedField.id) {
      return {
        ...f,
        [k]:
          k === "value" && f.type === "exists" ? v === true || v === "true" : v,
      };
    }
    return f;
  });
}

export function FilterFields({
  cols,
  filterForm,
  handleFormUpdate,
  handleFilterRemove,
  filterOptions,
  groupName,
  onGroupNameChange,
}: {
  cols: Array<{ id: string; name: string }>;
  filterForm: FilterFormField[];
  handleFormUpdate: (
    f: FilterFormField,
    k: keyof FilterFormField,
    v: string | number | boolean,
  ) => void;
  handleFilterRemove: (f: FilterFormField) => void;
  filterOptions: Array<{ id: FilterFormField["type"]; name: string }>;
  groupName: string;
  onGroupNameChange: (name: string) => void;
}) {
  const { remainingRows, filterWiseRows } = useStateContext();
  const [globalFilterName, setGlobalFilterName] = useState(groupName);
  
  useEffect(() => {
    console.log("Remaining rows updated:", remainingRows);
    console.log("Filter-wise rows:", filterWiseRows);
  }, [remainingRows, filterWiseRows]);

  useEffect(() => {
    // Update all filter names whenever globalFilterName changes
    if (globalFilterName) { // Check if globalFilterName is not empty
      filterForm.forEach(f => handleFormUpdate(f, "name", globalFilterName));
      onGroupNameChange(globalFilterName);
    }
  }, [globalFilterName, filterForm, handleFormUpdate, onGroupNameChange]);

  const handleGlobalNameChange = (newName: string) => {
    setGlobalFilterName(newName);
  };

  return (
    <div className="my-3 flex flex-col gap-8">
      {filterForm.length > 0 && (
        <input
          type="text"
          value={globalFilterName}
          onChange={(e) => handleGlobalNameChange(e.target.value)}
          className="rounded-md border border-gray-300 px-2 py-1 text-sm"
          placeholder="Enter filter group name"
        />
      )}

      {filterForm.length === 0 ? (
        <p>No filters added yet.</p>
      ) : (
        filterForm.map((f) => (
          <div
            key={f.id}
            id={f.id}
            className="flex flex-wrap justify-start gap-2"
          >
            <FormSelect
              overrideClasses="w-min"
              options={cols.map((i) => ({ id: i.id, name: i.name }))}
              setVal={(v) => {
                handleFormUpdate(f, "key", v);
              }}
              val={f.key}
            />
            {f.key && (
              <FormSelect
                overrideClasses="w-min"
                options={filterOptions}
                val={f.type}
                setVal={(v) => {
                  handleFormUpdate(f, "type", v);
                }}
              />
            )}
            {getCorrectField(f, { handleFormUpdate })}
            {f.key && filterWiseRows[f.key] !== undefined && (
              <div className="text-purple">
                {filterWiseRows[f.key]} rows removed
              </div>
            )}
            <IconButton
              icon={<CloseIcon />}
              overrideClasses="ml-auto h-[42px] w-[42px] max-w-min gap-0 border-none p-1.5"
              variant="outline"
              text={<></>}
              onClick={() => {
                handleFilterRemove(f);
              }}
            />
          </div>
        ))
      )}
    </div>
  );
}

function getCorrectField(
  f: FilterFormField,
  {
    handleFormUpdate,
  }: {
    handleFormUpdate(
      f: FilterFormField,
      k: keyof FilterFormField,
      v: FilterFormField["key"],
    ): void;
  },
) {
  if (f.type === "exists") {
    return (
      <FormSelect
        overrideClasses="w-min"
        options={[
          { id: "true", name: "True" },
          { id: "false", name: "False" },
        ]}
        val={f.value as string}
        setVal={(v) => {
          handleFormUpdate(f, "value", v);
        }}
      />
    );
  }
  if (
    f.type === "equals" ||
    f.type === "contains" ||
    f.type === "notEquals" ||
    f.type === "startsWith" ||
    f.type === "greaterThan" ||
    f.type === "lessThan"
  )
    return (
      <input
        id="default"
        type={
          f.type === "greaterThan" || f.type === "lessThan"
            ? "number"
            : undefined
        }
        value={f.value as string}
        className={twMerge(
          "h-10 max-w-min flex-1 rounded-md border border-neutral-200 px-2.5 py-3 text-sm leading-none outline-none focus-within:border-purple hover:border-purple",
        )}
        placeholder="e.g. Not found or x"
        onChange={(e) => {
          handleFormUpdate(f, "value", e.target.value);
        }}
      />
    );
}
