import React, { useState, useEffect, useCallback, memo, useRef } from "react";
import { IconButton } from "../elements/IconButton";
import { PencilIcon } from "@heroicons/react/24/outline";
import DeleteIcon from "../svgs/Delete";
import { SimpleSpinner } from "../elements/Spinner";
import backend_services from "src/services/backend_service";
import log from "../utils";
import { useSearchParams } from "react-router-dom";

// Add the useDebounce hook here
function useDebounce(callback: () => void, delay: number) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  return useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      callback();
    }, delay);
  }, [callback, delay]);
}

interface Filter {
  id: string;
  display: string;
  value: string;
  type: string;
  key: string;
  status: boolean;
}

interface FilterListProps {
  filters: Filter[]; // Ensure this is always an array
  isLoading: boolean;
  onFilterUpdate: () => void;
  user: any;
  chatId: string | undefined;
}

interface FilterGroupProps {
  displayName: string;
  filters: Filter[];
  user: any; // Replace 'any' with the correct user type
  chatId: string | undefined;
  onFilterUpdate: () => void;
  activeFilters: string[];
  onToggleActive: (filterId: string) => void;
  onSelectFilter: () => void; // New prop for selecting filter
}

export function FilterList({
  filters = [], // Default to an empty array if filters is null or undefined
  isLoading,
  onFilterUpdate,
  user,
  chatId,
}: FilterListProps) {
  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null); // Track selected filter
  const [pendingUpdates, setPendingUpdates] = useState(false);

  useEffect(() => {
    // Check if filters is an array before proceeding
    if (Array.isArray(filters)) {
      const initialActiveFilters = filters
        .filter((filter) => filter.status)
        .map((filter) => filter.id);
      setActiveFilters(initialActiveFilters);
    } else {
      console.error("Filters is not an array:", filters);
    }
  }, [filters]);

  const debouncedUpdate = useDebounce(() => {
    if (pendingUpdates) {
      onFilterUpdate();
      setPendingUpdates(false);
    }
  }, 500);

  const handleToggleActive = useCallback((filterId: string) => {
    setActiveFilters((prev) => {
      const newActiveFilters = new Set(prev);
      if (newActiveFilters.has(filterId)) {
        newActiveFilters.delete(filterId);
      } else {
        newActiveFilters.add(filterId);
      }
      setPendingUpdates(true);
      return Array.from(newActiveFilters);
    });
  }, []);

  useEffect(() => {
    if (pendingUpdates) {
      debouncedUpdate();
    }
  }, [activeFilters, pendingUpdates, debouncedUpdate]);

  if (isLoading) {
    return <div>Loading filters...</div>;
  }

  // Check if filters is an array before accessing length
  if (!Array.isArray(filters) || filters.length === 0) {
    return <div>No filters available</div>;
  }

  const groupedFilters = filters.reduce(
    (acc, filter) => {
      if (!acc[filter.display]) {
        acc[filter.display] = [];
      }
      acc[filter.display].push(filter);
      return acc;
    },
    {} as Record<string, Filter[]>,
  );

  return (
    <div className="p-2">
      {Object.entries(groupedFilters).map(([displayName, filtersGroup]) => (
        <FilterGroup
          key={displayName}
          displayName={displayName}
          filters={filtersGroup}
          user={user}
          chatId={chatId}
          onFilterUpdate={onFilterUpdate}
          activeFilters={activeFilters}
          onToggleActive={handleToggleActive}
          onSelectFilter={() => setSelectedFilter(displayName)} // Set selected filter
        />
      ))}
      {/* Render buttons for the selected filter */}
      {selectedFilter && (
        <div className="mt-4">
          <h3>Actions for {selectedFilter}</h3>
          {/* Render edit, delete, and toggle buttons here */}
        </div>
      )}
    </div>
  );
}

const FilterGroup = memo(
  ({
    displayName,
    filters,
    user,
    chatId,
    onFilterUpdate,
    activeFilters,
    onToggleActive,
    onSelectFilter, // New prop for selecting filter
  }: FilterGroupProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editLoading, setEditLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [editableValue, setEditableValue] = useState(displayName);
    const [isToggled, setIsToggled] = useState(filters[0]?.status || false);
    const [searchParams, setSearchParams] = useSearchParams();

    // Example usage of searchParams
    useEffect(() => {
      console.log("Current search parameters:", searchParams.toString());
    }, [searchParams]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setEditableValue(event.target.value);
    };

    const handleBlur = () => {
      updateFilterName(editableValue);
    };

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
      if (event.key === "Enter") {
        updateFilterName(editableValue);
      }
    }

    function updateFilterName(newName: string) {
      setIsEditing(false);
      if (!newName || displayName === newName || !user || !chatId) {
        setEditableValue(displayName); // Reset to original name if invalid
        return;
      }
      setEditLoading(true);

      const updatePromises = filters.map((filter) =>
        backend_services.fetchProtectedData(
          `/rename_filter`, // Updated endpoint
          user?.getIdToken(),
          undefined,
          "POST",
          {
            chat_id: chatId, // Include chat_id
            current_name: displayName, // Current name of the filter
            new_name: newName, // New name for the filter
          },
        ),
      );

      Promise.all(updatePromises)
        .then((responses) => {
          responses.forEach((res) => log(res));
          onFilterUpdate();
        })
        .catch((error) => {
          console.error("Error updating filter names:", error);
          setEditableValue(displayName); // Reset to original name on error
        })
        .finally(() => {
          setEditLoading(false);
        });
    }

    const handleDelete = async () => {
      if (!user || !chatId) return;
      setDeleteLoading(true);
      try {
        const encodedFilterName = encodeURIComponent(displayName);
        const response = await backend_services.fetchProtectedData(
          `/${chatId}/filter/delete?filter_name=${encodedFilterName}`,
          user?.getIdToken(),
          undefined,
          "DELETE",
        );
        const data = await response.json();
        log(data);
        onFilterUpdate();
      } catch (error) {
        console.error("Error deleting filter:", error);
      } finally {
        const encoded = encodeURIComponent(JSON.stringify(filters));
        setSearchParams((prev) => {
          prev.set("filter", encoded);
          return prev;
        });
        setDeleteLoading(false);
      }
    };

    const handleToggle = async (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const newToggleState = !isToggled;
      setIsToggled(newToggleState); // Immediately update local state

      if (!user || !chatId) return;

      try {
        const encodedFilterName = encodeURIComponent(displayName);
        await backend_services.fetchProtectedData(
          `/toggle_filter_status?chat_id=${chatId}&filter_name=${encodedFilterName}&status=${newToggleState}`,
          user?.getIdToken(),
          undefined,
          "POST",
        );

        // Update the search parameters to reflect the new toggle state
        const encoded = encodeURIComponent(JSON.stringify(filters));
        setSearchParams((prev) => {
          prev.set("filter", encoded);
          return prev;
        });

        // Update the active filters in the parent component without triggering a full refresh
        filters.forEach((filter) => onToggleActive(filter.id));
      } catch (error) {
        console.error("Error toggling filter status:", error);
        // Revert the toggle if the API call fails
        setIsToggled(!newToggleState);
      }
    };

    return (
      <div className="group relative mb-2 flex w-full items-center rounded p-2">
        <span className="mr-2 flex-shrink-0">
          <img
            className="h-[19.63px] w-[21.02px]"
            src="/curator-tool.png"
            alt="Curator Tool Icon"
          />
        </span>
        {editLoading ? (
          <SimpleSpinner radius={20} overrideClasses="border-black" />
        ) : isEditing ? (
          <input
            className="h-full flex-grow px-3 py-2 outline-none"
            type="text"
            value={editableValue}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            autoFocus
          />
        ) : (
          <div
            className="flex-grow truncate font-medium"
            onClick={onSelectFilter}
          >
            {displayName}
          </div>
        )}
        {!isEditing && !editLoading && (
          <div className="ml-2 flex items-center gap-2">
            <div
              className="container"
              onClick={handleToggle}
              onMouseDown={(e) => e.preventDefault()} // Prevent focus change
            >
              <input
                type="checkbox"
                className="checkbox"
                checked={isToggled}
                readOnly
              />
              <span className="switch">
                <span className="slider"></span>
              </span>
            </div>
            <IconButton
              overrideClasses="z-10 h-8 w-8 gap-0 bg-white"
              icon={<PencilIcon width={20} />}
              text={<></>}
              variant="outline"
              onClick={(e) => {
                setIsEditing(true);
                e.preventDefault();
                e.stopPropagation();
              }}
            />
            <IconButton
              overrideClasses="z-10 h-8 w-8 gap-0 bg-white"
              icon={
                deleteLoading ? (
                  <SimpleSpinner radius={20} overrideClasses="border-black" />
                ) : (
                  <DeleteIcon width={20} />
                )
              }
              text={<></>}
              variant="outline"
              onClick={(e) => {
                handleDelete();
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </div>
        )}
      </div>
    );
  },
);
