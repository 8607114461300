import React, { useState, useEffect } from "react";
import CustomDropdown from "../elements/CustomDropDownMenu";
import { IconButton } from "../elements/IconButton";
import { IconItem } from "../../pages/sidebar/ChatNameBox";
import ColumnsIcon from "../svgs/Columns";
import FullScreenIcon from "../svgs/FullScreen";
import RowsIcon from "../svgs/Rows";
import ThreeDotsIcon from "../svgs/ThreeDots";
import DownloadIcon from "../svgs/Download";
import DeleteIcon from "../svgs/Delete";
import EditIcon from "../svgs/Edit";
import ShareIcon from "../svgs/Share";
import useChatDetails from "src/hooks/useChatDetails";
import { useStateContext } from "src/context/StateContext";
import { getColumnDisplayName, numberFormatter } from "../utils";
import { Column } from "src/hooks/useChatTable";
import StarsIcon from "../svgs/Stars";
import KurationSmallIcon from "../svgs/KurationSmall";
import { ArrowPathIcon, PencilIcon } from "@heroicons/react/24/outline";
import backend_services from "src/services/backend_service";
import { useParams } from "react-router-dom";
import { useAuth } from "src/context/AuthContext";
import { ChangeEvent } from "react";
import { SimpleSpinner } from "../elements/Spinner";
import { FilterList } from "src/components/table/filterList";
import Switcher3 from "../elements/Switcher3";

export default function TableOptions({
  rowCount,
  uniqueCols,
  column_list,
  columnFilters,
  downloadHandler,
  type,
  updateTotalCount,
}: {
  rowCount: number;
  uniqueCols: number;
  type: "search" | "default" | "final" | "noTable";
  column_list?: Array<Column>;
  columnFilters?: any[];
  downloadHandler: () => void;
  updateTotalCount?: () => void;
}) {
  const [filters, setFilters] = useState([]);
  const { chatId: currentOpenChat } = useParams();
  const { chatDetails } = useChatDetails(currentOpenChat ?? null);
  const tableId = chatDetails?.table_id;
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const { chatId } = useParams();
  const { setChatOpen, chatOpen } = useStateContext();
  const [toggleStates, setToggleStates] = useState<{ [key: string]: boolean }>(
    {},
  );
  console.log(
    "::::::::::::::::::::::::::::::::::::::::::::::::",
    columnFilters,
  );
  useEffect(() => {
    const initialToggleStates: { [key: string]: boolean } = {};
    columnFilters?.forEach((filter) => {
      initialToggleStates[filter.column_id] = filter.status;
    });
    setToggleStates(initialToggleStates);
  }, [columnFilters]);

  const fetchFilters = async () => {
    setIsLoading(true);
    try {
      const response = await backend_services.fetchProtectedData(
        `/get_chat_filters/?chat_id=${chatId}`,
        user?.getIdToken(),
      );
      const data = await response.json();
      setFilters(data);
    } catch (error) {
      console.error("Error fetching filters:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleFilterUpdate = () => {
    fetchFilters();
  };

  const handleToggle = async (columnId: string) => {
    const newToggleState = !toggleStates[columnId];

    setToggleStates((prev: { [key: string]: boolean }) => ({
      ...prev,
      [columnId]: newToggleState,
    }));

    try {
      const response = await backend_services.fetchProtectedData(
        `/store_column_filter?chat_id=${chatId}&table_id=${tableId}&column_id=${columnId}&status=${newToggleState}`,
        user?.getIdToken(),
        undefined,
        "POST",
      );

      const data = await response.json();
      console.log("Column filter saved:", data);
    } catch (error) {
      console.error("Error saving column filter:", error);
    }
  };

  return (
    <div className=" flex flex-grow flex-row items-center justify-between gap-6 md:gap-2">
      <div className="flex flex-row items-center gap-1 md:gap-2">
        <CustomDropdown
          opener={
            <IconItem
              collapsible={true}
              icon={
                <>
                  <ArrowPathIcon
                    width={20}
                    className={`${updateTotalCount ? "hidden group-hover:block" : "hidden"}`}
                  />
                  <RowsIcon
                    className={`${updateTotalCount ? "group-hover:hidden" : ""}`}
                  />
                </>
              }
              onClick={() => {
                updateTotalCount?.();
                fetchFilters();
              }}
              text={
                <>
                  <span
                    className={`${updateTotalCount ? "hidden group-hover:block " : "hidden"}`}
                  >
                    Refresh
                  </span>
                  <span
                    className={`${updateTotalCount ? "group-hover:hidden" : ""}`}
                  >
                    {numberFormatter.format(rowCount)} Rows
                  </span>
                </>
              }
              overrideClasses="rounded-md bg-transparent hover:bg-lightpurple"
            />
          }
          options={[
            <FilterList
              filters={filters}
              isLoading={isLoading}
              onFilterUpdate={handleFilterUpdate}
              user={user}
              chatId={chatId}
            />,
          ]}
        />

        <CustomDropdown
          opener={
            <IconItem
              collapsible={true}
              icon={<ColumnsIcon />}
              onClick={() => {}}
              text={`${uniqueCols || 0} Columns`}
              overrideClasses="rounded-md bg-transparent hover:bg-lightpurple"
            />
          }
          overrideClasses="max-h-[400px] w-96 overflow-y-auto"
          options={[
            <ColumnOptions
              columns={column_list || []}
              toggleStates={toggleStates}
              onToggle={handleToggle}
              columnFilters={columnFilters}
            />,
          ]}
        />
      </div>
      <div>
        <div className="hidden flex-row gap-2 md:flex">
          <IconButton
            title={`Max ${type === "default" ? "10,000" : "1000"} rows`}
            overrideClasses="h-[42px] w-[42px] flex-grow gap-0 border-purple p-1.5"
            onClick={() => {
              downloadHandler();
            }}
            variant="outline"
            icon={<DownloadIcon className="h-6 w-6" />}
            text={<></>}
          />
          <IconButton
            overrideClasses="h-[42px] w-[42px] flex-grow gap-0 border-purple p-1.5"
            onClick={() => {
              setChatOpen(!chatOpen);
            }}
            variant="outline"
            icon={<FullScreenIcon className="h-6 w-6" />}
            text={<></>}
          />
        </div>
        <div className=" hidden h-[42px] w-[42px] items-center justify-center">
          <CustomDropdown
            opener={
              <IconItem
                collapsible={true}
                icon={<ThreeDotsIcon />}
                onClick={() => {}}
                text="Settings"
                overrideClasses="rounded-md bg-transparent hover:bg-lightpurple  "
              />
            }
            options={chatOptions()}
            overrideClasses="left-8"
          />
        </div>
      </div>
    </div>
  );
}
function ColumnOptions({
  columns,
  toggleStates,
  onToggle,
  columnFilters,
}: {
  columns: Column[];
  toggleStates: { [key: string]: boolean };
  onToggle: (columnId: string) => Promise<void>;
  columnFilters?: any[];
}) {
  const { user } = useAuth();
  const { chatId } = useParams();
  const [reorderLoading, setReorderLoading] = useState(false);
  function handleReorder(newCols: Column[]) {
    function saveUpdates(updates: { [key: string]: number }) {
      setReorderLoading(true);
      backend_services
        .fetchProtectedData(
          `/chat/${chatId}/column/reorder`,
          user?.getIdToken(),

          undefined,
          "POST",
          {
            updates,
          },
        )
        .then((res) => {
          // console.log(res);
        })
        .finally(() => {
          setReorderLoading(false);
        });
    }
    function commitUpdates(items: Column[]) {
      const updates: { [key: string]: number } = {};
      items.forEach((item, index) => {
        const targetItem = columns.find((c) => c.id === item.id);
        if (targetItem && columns.indexOf(targetItem) !== index) {
          updates[targetItem.id] = index;
        }
      });
      console.log(updates);
      if (Object.keys(updates).length > 0) {
        saveUpdates(updates);
      }
    }
    commitUpdates(newCols);
  }
  if (!columns) {
    return <></>;
  }
  const handleDragStart = (e: any, index: number) => {
    e.dataTransfer.setData("drag-index", index);
  };

  const handleDrop = (e: any, dropIndex: number) => {
    const dragIndex = Number(e.dataTransfer.getData("drag-index"));
    console.log({ dragIndex, dropIndex });
    if (dragIndex === dropIndex) {
      return;
    }
    const itemDragged = columns[dragIndex];
    const remainingItems = columns.filter((_, index) => index !== dragIndex);
    remainingItems.splice(dropIndex, 0, itemDragged);
    console.log(remainingItems);
    handleReorder(remainingItems);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault(); // Necessary for the onDrop to fire
  };
  return (
    <ul className="relative m-0 p-0">
      {columns.map((column, index) => (
        <ColumnWithActions
          key={column.id}
          draggable
          onDragStart={(e) => handleDragStart(e, index)}
          onDrop={(e) => handleDrop(e, index)}
          onDragOver={handleDragOver}
          column={column}
          isToggled={toggleStates[column.id] || false}
          onToggle={() => onToggle(column.id)}
        />
      ))}
      {reorderLoading && (
        <>
          <div className="absolute inset-0 h-full rounded bg-slate-200 bg-opacity-80 backdrop-blur-sm transition-all duration-300 ">
            <div className="sticky top-1/2 z-10 flex items-center justify-center opacity-100 ">
              <div className="flex flex-col items-center justify-center gap-2">
                <SimpleSpinner radius={20} overrideClasses="border-black" />
                <span>Saving...</span>
              </div>
            </div>
          </div>
        </>
      )}
    </ul>
  );
}
interface ColumnActionProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  column: Column;
  isToggled: boolean;
  onToggle: () => void;
}
function ColumnWithActions({
  column,
  isToggled,
  onToggle,
  ...props
}: ColumnActionProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const { user } = useAuth();
  const { chatId } = useParams();
  const [editableValue, setEditableValue] = useState(
    getColumnDisplayName(column),
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEditableValue(event.target.value);
  };

  const handleBlur = () => {
    updateColumnName(column, editableValue);
  };

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      updateColumnName(column, editableValue);
    }
  }

  function updateColumnName(column: Column, newName: string) {
    setIsEditing(false);
    if (!newName || getColumnDisplayName(column) === newName) {
      return;
    }
    setEditLoading(true);
    backend_services
      .fetchProtectedData(
        `/chat/${chatId}/column/rename`,
        user?.getIdToken(),
        undefined,
        "POST",
        {
          column_id: column.id,
          new_name: newName,
        },
      )
      .then((res) => {
        console.log(res);
      })
      .finally(() => {
        setEditLoading(false);
      });
  }

  return (
    <button
      onClick={() => {}}
      className="flex w-full gap-2 rounded pl-2.5 transition-all duration-300 hover:bg-grey-600"
      {...props}
    >
      <div className="group relative z-0 line-clamp-1 flex max-w-full flex-grow items-center gap-2 py-2 text-left">
        {column.type === "tool_column" ? (
          <img width={20} src={column.tool.icon} alt={column.tool.name} />
        ) : column.type === "custom_tool_column" ? (
          <div className="h-6 w-6">
            <StarsIcon fill="#e08588" />
          </div>
        ) : (
          <KurationSmallIcon width={20} height={15} />
        )}
        {editLoading ? (
          <SimpleSpinner radius={20} overrideClasses="border-black" />
        ) : isEditing ? (
          <input
            className="h-full w-full px-3 py-4 outline-none"
            type="text"
            value={
              typeof editableValue === "string"
                ? editableValue
                : JSON.stringify(editableValue)
            }
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            autoFocus
          />
        ) : (
          <p className="truncate">{getColumnDisplayName(column)}</p>
        )}

        {!isEditing && !editLoading && (
          <div className="invisible absolute right-0 flex h-full gap-1 py-1 opacity-0 transition-all duration-300 group-hover:visible group-hover:opacity-100">
            <Switcher3
              isChecked={isToggled}
              onChange={() => {
                onToggle();
              }}
            />

            <IconButton
              overrideClasses="z-10 h-full gap-0 bg-white px-2"
              icon={<PencilIcon width={20} />}
              text={<></>}
              variant="outline"
              onClick={(e) => {
                setIsEditing(true);
                e.preventDefault();
                e.stopPropagation();
              }}
            />
            <ColumnDeleteButton column={column} />
          </div>
        )}
      </div>
    </button>
  );
}

function ColumnDeleteButton({ column }: { column: Column }) {
  const { chatId } = useParams();
  const { user } = useAuth();
  const [deleteLoading, setDeleteLoading] = useState(false);
  if (!chatId || !user) {
    return <></>;
  }

  function handleDelete() {
    setDeleteLoading(true);
    backend_services
      .fetchProtectedData(
        `/chat/${chatId}/column/delete`,
        user?.getIdToken(),
        undefined,
        "DELETE",
        {
          column_id: column.id,
        },
      )
      .then((res) => {
        console.log(res);
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  }

  return (
    <IconButton
      overrideClasses="z-10 h-full gap-0  bg-white px-2"
      icon={
        deleteLoading ? (
          <SimpleSpinner radius={20} overrideClasses="border-black" />
        ) : (
          <DeleteIcon width={20} />
        )
      }
      text={<></>}
      variant="outline"
      onClick={(e) => {
        handleDelete();

        e.preventDefault();
        e.stopPropagation();
      }}
    />
  );
}
function chatOptions() {
  return [
    <IconItem
      onClick={() => {
        console.log("share clicked");
      }}
      text="Share"
      icon={<ShareIcon />}
    />,
    <IconItem
      onClick={() => {
        console.log("share clicked");
      }}
      text="Rename"
      icon={<EditIcon />}
    />,
    <IconItem
      color="text-error"
      onClick={() => {
        console.log("share clicked");
      }}
      text="Delete"
      icon={<DeleteIcon />}
    />,
  ];
}
