import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "src/context/AuthContext";
import { PreCuratedListItem } from "src/models/preCuratedList";
import backend_services from "src/services/backend_service";

export function usePreCuratedListDetail() {
  const { user } = useAuth();
  const { listId } = useParams();
  return useQuery({
    queryKey: ["pre_curated_list_detail", listId],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return (
        user &&
        backend_services
          .fetchProtectedData(
            `/pre_curated_lists/${listId}`,
            user?.getIdToken(),
          )
          .then((res) => res.json() as Promise<PreCuratedListItem>)
      );
    },
  });
}

export function usePreCuratedCompanies({
  pageNum,
  listFilter,
}: {
  pageNum: number;
  listFilter: any[];
}) {
  const { user } = useAuth();
  const { listId } = useParams();
  return useQuery({
    queryKey: [
      "pre_curated_list_items",
      listId,
      pageNum,
      JSON.stringify(listFilter),
    ],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return (
        user &&
        backend_services
          .fetchProtectedData(
            `/pre_curated_lists/${listId}/items?page_number=${pageNum}&page_size=100`,
            user?.getIdToken(),
            undefined,
            "POST",
            { filters: listFilter },
          )
          .then((res) => res.json())
      );
    },
  });
}

export async function fetchFilteredCompanies({
  listFilter,
  chatId,
  page,
  user
}: {
  listFilter: any[];
  chatId: string | undefined;
  page: number;
  user: any;
}) {
  try {
    const response = await backend_services.fetchProtectedData(
      `/get_chat_data?chat_id=${chatId}&page_size=100&page_number=${page}`,
      user?.getIdToken(),
      undefined,
      "POST",
      { filters: listFilter }
    );

    const result = await response.json();
    const companies = result.company_list;
    const pagination = result.pagination;
    const ids = result.filtered_ids;
    const remainingRows = result.remaining_rows;
    const filterWiseRows = result.filter_wise_rows;
    const columnsLoading = result.columns_loading;
    const totalCompanies = result.total_companies;
    return {
      companies,
      pagination,
      ids,
      remainingRows,
      filterWiseRows,
      columnsLoading,
      totalCompanies
    };
  } catch (error) {
    console.error("Error fetching filtered companies:", error);
    throw error;
  }
}
export function useFilteredCompanies(listFilter: any[], chatId: string | undefined, page: number) {
  const { user } = useAuth();
  return useQuery({
    queryKey: ["filtered_companies", chatId, page, JSON.stringify(listFilter)],
    queryFn: () => fetchFilteredCompanies({ listFilter, chatId, page, user }),
    enabled: !!user && !!chatId,
  });
}




